import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, from } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  return = '';
  loggedIn$: Observable<boolean>;
  googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  constructor(public afAuth: AngularFireAuth, private router: Router, private route: ActivatedRoute) {
    this.loggedIn$ = from(this.afAuth.authState).pipe(
      map((user: any) => {
        if (user){
          return true;
        }
        return false;
      }),
      tap((authenticated: any)=>{
        if (!authenticated) this.logout();
      })
    );
   }
  ngOnInit() {
    // Get the query params
    this.route.queryParams
       .subscribe(params => this.return = params['return'] || '/home');
 }

  login(): void {
    console.log("trying login")
    firebase.auth().signInWithRedirect(this.googleAuthProvider).then((res)=>{
      console.log("Res " + res);
      this.router.navigate(["/transaction"]);
    }).catch((error)=>{
      console.log("Error logging in");
      console.log(error);
      this.router.navigateByUrl("error");
    })
  }

  logout(): void {
    firebase.auth().signOut().then((res)=>{
      this.router.navigate(["login"]);
    }).catch((error)=>{
      console.log("Error logging in");
      this.router.navigateByUrl("error");
    })
  }

  isAuthenticated(): Observable<boolean> {
    return this.loggedIn$;
  }
}
