// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDUzkhAVJLKSUzKvP5eGprqWQM7ySx21J4",
    authDomain: "noahbudget.firebaseapp.com",
    databaseURL: "https://noahbudget-default-rtdb.firebaseio.com",
    projectId: "noahbudget",
    storageBucket: "noahbudget.appspot.com",
    messagingSenderId: "879744797542",
    appId: "1:879744797542:web:f5a180baaf1b31daa2d6a7",
    measurementId: "G-1Y2LHLPLKM"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
